import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Tabs,
  Tab,
  Stack,
  Unstable_Grid2 as Grid,
  Typography,
  Skeleton,
  Alert,
  LinearProgress,
  Link,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Progress } from "../../../components/DataGrid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import useNavStore from "../../../stores/nav";
import useAuthStore from "../../../stores/auth";
import useTaskStore from "../../../stores/task";

import { fetchData } from "../../../fetchData";
import { fetchRRData } from "../../../fetchRRData";

import TaskDetailDrawer from "./drawers/taskDetailDrawer";

function Tasks() {
  const setHeaderTitle = useNavStore((state: any) => state.setHeader);
  const company = useAuthStore((state) => state.company);
  const toggleTaskDrawer = useTaskStore((state) => state.toggleDrawer);
  const download = useTaskStore((state) => state.download);
  const [initProgress, setInitProgress] = useState(true);
  const [progress, setProgress] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState<any>([]);

  const data = async () => {
    setProgress(true);

    try {
      const response = await fetchData(
        `/organizations/${company.reference}/tasks?page=${page}`,
        {
          method: "GET",
        },
      );

      if (response) {
        setRows([...rows, ...response]);
      }
    } catch (error) {
      setError(true);
    }

    setProgress(false);
    setInitProgress(false);
  };

  useEffect(() => {
    data();
    setHeaderTitle("Atividades");
  }, []);

  useEffect(() => {
    data();
  }, [page]);

  return (
    <Card>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Atividades</TableCell>
            <TableCell>Docs</TableCell>
            <TableCell>
              <MoreHorizIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((params: any) => (
            <TableRow key={params} hover>
              <TableCell>
                <Link
                  component="button"
                  onClick={() => toggleTaskDrawer(params)}
                >
                  {params.title}
                </Link>
              </TableCell>
              <TableCell>{params.attachments_count}</TableCell>
              <TableCell>
                <Button
                  size="small"
                  startIcon={<CloudDownloadIcon />}
                  onClick={() => download(params.id, "zip")}
                  variant="outlined"
                  color="success"
                >
                  Zip
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {progress && !initProgress && <Progress />}
      {!progress && !initProgress && (
        <Button
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            setPage(page + 1);
          }}
        >
          Carregar mais
        </Button>
      )}
      <TaskDetailDrawer />
    </Card>
  );
}

export default Tasks;
